import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageSpanishQuery = gql`
query homeQuery {
  homepages (sort: "displayPosition", locale: "es-US") {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        textList      
      }
    }
    menuIcon {
      id
      url
    }
    displayPosition
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqSpanishQuery = gql`
query faqQuery {
  faqs (locale: "es-US") {
    id
    question
    answer
    category
  }
}
`

export const faqCategorySpanishQuery = gql`
query faqCategoryQuery {
  faqCategories (locale: "es-US") {
    id
    categoryName
    displayType
  }
}
`

export const srlGuideSpanishQuery = gql`query srlGuideQuery {
  srlGuides (sort: "id", locale: "es-US"){
    id
    category
    guideName
    srlGuideLink
    srlFile {
      id
      url
    }
  }
}`


//---------------------------- Map Page Queries ---------------------------------



export const mapSpanishQuery = gql`
query mapQuery {
  maps (locale: "es-US") {
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorSpanishQuery = gql`
query floorQuery {
  floors (sort: "floorNumber", locale: "es-US"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`

//---------------------------- Froms Page Queries ---------------------------------

export const formCategorySpanishQuery = gql `
query formCategoryQuery {
  formCategories (locale: "es-US") {
    id
    categoryName
    displayType
    categoryType
  }
}
`

export const allFormFirstSpanishQuery = gql`
query allFormQuery {
  allForms(start: 0, sort: "id", locale: "es-US") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondSpanishQuery = gql`
query allFormQuery {
  allForms(start: 100, sort: "id", locale: "es-US") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormThirdSpanishQuery = gql`
query allFormQuery {
  allForms(start: 200, sort: "id" , locale: "es-US") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const formTypeSpanishQuery = gql`
query formTypeQuery {
    formTypes(sort: "displayOrder", locale: "es-US") {
        id
        formSubType
        displayType
        partition
    }
}
`

export const formPacketSpanishQuery = gql`
query formPacketQuery {
    formPackets (locale: "es-US") {
        id
        packetName
        packetDesc
        packetLink
        packetFiles{
            id
            url
        }
    }
}
`
export const CourtTypeSpanishQuery = gql`
query formCourtTypes {
    courtTypes (locale: "es-US") {
        id
        courtType
        category{
          ... on ComponentFormCategoryCategoryForm{
            categoryName
            categoryType
          }
        }
    }    
}`
