import {
    floorEnglishQuery,
    allFormFirstEnglishQuery,
    allFormSecondEnglishQuery,
    allFormThirdEnglishQuery,
    mapEnglishQuery,
    faqEnglishQuery,
    faqCategoryEnglishQuery,
    srlGuideEnglishQuery,
    formCategoryEnglishQuery,
    formTypeEnglishQuery,
    formPacketEnglishQuery,
    homepageEnglishQuery,
    CourtTypeEnglishQuery
} from '@/graphql/query';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        homepageEnglish: [],
        floorsEnglish: [],
        mapsEnglish: new Map(),
        formsEnglish: new Map(),
        faqsEnglish: [],
        faqCategoryEnglish: [],
        srlGuideEnglish: new Map(),
        formCategoriesEnglish: [],
        formTypesEnglish: [],
        formPacketsEnglish: new Map(),
        courtTypeEnglish: new Map()

    },
    getters: {
        getHomepageEnglish: state => state.homepageEnglish,
        getFloorsEnglish: state => state.floorsEnglish,
        getMapsEnglish: state => state.mapsEnglish,
        getFaqsEnglish: state => state.faqsEnglish,
        getFaqCategoriesEnglish: state => state.faqCategoryEnglish,
        getSrlGuideEnglish: state => state.srlGuideEnglish,
        getFormsEnglish: state => state.formsEnglish,
        getFormCategoryEnglish: state => state.formCategoriesEnglish,
        getFormTypeEnglish: state => state.formTypesEnglish,
        getFormPacketsEnglish: state => state.formPacketsEnglish,
        getCourtTypeEnglish: state => state.courtTypeEnglish
    },
    mutations: {
        setHomepageEnglish(state, page) {
            state.homepageEnglish.push(page)
        },
        setFloorsEnglish(state, floor) {
            console.log(floor)
            state.floorsEnglish.push(floor)
        },
        setMapsEnglish(state, map) {
            state.mapsEnglish.set(map.mapName.toLowerCase(), map)
        },
        setFormsEnglish(state, form) {
            state.formsEnglish.set(form.id, form)
        },
        setFaqsEnglish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category
                }
                state.faqsEnglish.push(data)
            })
            //state.faqs = faqs
        },
        setFaqCategoryEnglish(state, faqCategories) {
            state.faqCategoryEnglish = faqCategories
        },
        setSrlGuideEnglish(state, guide) {
            state.srlGuideEnglish.set(guide.guideName.toLowerCase(), {
                guideName: guide.guideName,
                category: guide.category,
                srlFile: guide.srlFile.url,
                guideUrl: guide.srlGuideLink
            })
        },
        setFormCategoriesEnglish(state, categories) {
            state.formCategoriesEnglish = categories
        },
        setFormTypesEnglish(state, types) {
            state.formTypesEnglish = types
        },
        setFormPacketsEnglish(state, packet) {
            state.formPacketsEnglish.set(packet.packetName.toLowerCase(), packet)
        },
        setCourtTypesEnglish(state, formCourtType) {
            state.courtTypeEnglish.set(formCourtType.courtType, formCourtType.category)
        }
    },
    actions: {
        fetchHomePageEnglish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: homepageEnglishQuery
            }).then(items => {
                items.data.homepages.forEach(page => {
                    dispatch('saveInCache', page.menuIcon.url)
                        .then(response => {
                            page.menuIcon.url = response
                            commit('setHomepageEnglish', page);
                        })
                })

            })
        },
        // Fetch Floor from strapi
        fetchFloorsEnglish({ commit, dispatch, getters }) {
            console.log('Inside Fetch Floors english')

            getters.getApolloClient.query({
                query: floorEnglishQuery
            }).then(floors => {
                floors.data.floors.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsEnglish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsEnglish({ commit, getters, dispatch }) {

            getters.getApolloClient.query({
                query: mapEnglishQuery
            }).then(maps => {
                maps.data.maps.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsEnglish', {
                                mapName: map.mapName,
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })
            })
        },

        // Fetch Forms from strapi
        fetchFormsEnglish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: allFormFirstEnglishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsEnglish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })

            })


            getters.getApolloClient.query({
                query: allFormSecondEnglishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsEnglish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })


            })

            getters.getApolloClient.query({
                query: allFormThirdEnglishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsEnglish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })


            })
        },

        fetchFaqsEnglish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqEnglishQuery
            }).then(faqs => {
                commit('setFaqsEnglish', faqs.data.faqs);

            })
        },

        fetchFaqCategoryEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqCategoryEnglishQuery
            }).then(faqCategory => {
                commit('setFaqCategoryEnglish', faqCategory.data.faqCategories)

            })
        },

        fetchSrlGuideEnglish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: srlGuideEnglishQuery
            }).then(srlGuides => {
                srlGuides.data.srlGuides.forEach(guide => {
                    dispatch('saveInCache', guide.srlFile.url)
                        .then(response => {
                            guide.srlFile.url = response;
                            commit('setSrlGuideEnglish', guide);
                        })
                })

            })
        },

        fetchFormCategoriesEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: formCategoryEnglishQuery
            }).then(categories => {
                commit('setFormCategoriesEnglish', categories.data.formCategories)

            })
        },

        fetchFormTypeEnglish({ commit, getters }) {
            getters.getApolloClient.query({
                query: formTypeEnglishQuery
            }).then(types => {
                commit('setFormTypesEnglish', types.data.formTypes)

            })
        },
        fetchFormCourtTypeEnglish({ commit, getters }) {

            getters.getApolloClient.query({
                query: CourtTypeEnglishQuery
            }).then(types => {
                types.data.courtTypes.forEach(courtType => {
                    commit('setCourtTypesEnglish', courtType)
                })

            })
        },
        fetchFormPacketsEnglish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: formPacketEnglishQuery
            }).then(packets => {
                packets.data.formPackets.forEach(packet => {
                    let files = [];
                    packet.packetFiles.forEach(file => {
                        dispatch('saveInCache', file.url)
                            .then(response => {
                                files.push(response)

                            })
                    })
                    commit('setFormPacketsEnglish', {
                        packetName: packet.packetName,
                        packetDesc: packet.packetDesc,
                        packetLink: packet.packetLink,
                        packetFiles: files
                    })
                })

            })
        }
    }
}
