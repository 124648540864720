import gql from 'graphql-tag'

// ---------------------------- Homepages --------------------------------------------

export const homepageEnglishQuery = gql`
query homeQuery {
  homepages(sort: "displayPosition") {
    id
    speech    
    menuName     
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        type
        textList      
      }
    }
    menuIcon {
      id
      url
    }
    displayPosition
  }
}
`

// ------------------------------ Faqs ----------------------------------------------

export const faqEnglishQuery = gql`
query faqQuery {
  faqs {
    id
    question
    answer
    category
  }
}
`
export const faqCategoryEnglishQuery = gql`
query faqCategoryQuery {
  faqCategories(sort: "displayOrder") {
    id
    categoryName
    displayType
    displayOrder
  }
}
`

export const srlGuideEnglishQuery = gql`
query srlGuideQuery {
  srlGuides (sort: "id"){
    id
    category
    guideName
    srlGuideLink
    srlFile {
      id
      url
    }
  }
}`

// ------------------------------ Maps ----------------------------------------------
export const mapEnglishQuery = gql`
query mapQuery {
  maps{
    id
    mapName
    mapFloor
    speech
    mapImage {
      id
      url
    }
  }
}
`

export const floorEnglishQuery = gql`
query floorQuery {
  floors (sort: "floorNumber"){ 
    id
    floorName
    floorNumber
    speech
    floorImage {
      id
      url
    }
  }
}
`
// --------------------------------------------- Forms --------------------------------------

export const formCategoryEnglishQuery = gql`
query formCategoryQuery {
  formCategories {
    id
    categoryName
    displayType
    categoryType
  }
}
`

export const allFormFirstEnglishQuery = gql`
query allFormQuery {
  allForms(start: 0, sort: "id") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondEnglishQuery = gql`
query allFormQuery {
  allForms(start: 100, sort: "id") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const allFormThirdEnglishQuery = gql`
query allFormQuery {
  allForms(start: 200, sort: "id") {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    formPdf{
      id
      url
    }
  }
}
`

export const formTypeEnglishQuery = gql`
query formTypeQuery {
    formTypes(sort: "displayOrder") {
        id
        formSubType
        displayType
        partition
    }
}
`

export const formPacketEnglishQuery = gql`
query formPacketQuery {
    formPackets {
        id
        packetName
        packetDesc
        packetLink
        packetFiles{
            id
            url
        }
    }
}
`
export const CourtTypeEnglishQuery = gql`
query formCourtTypes {
    courtTypes {
        id
        courtType
        category{
          ... on ComponentFormCategoryCategoryForm{
            categoryName
            categoryType
          }
        }
    }    
}`
