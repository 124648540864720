<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field
            outlined
            placeholder="Tap to search by Full Name or Case Number."
            rounded
            height="55px"
            class="text-h5"
            hide-details
            :append-icon="expand ? '' : 'mdi-magnify'"
            :append-outer-icon="expand ? 'mdi-keyboard-off-outline' : ''"
            @click:append-outer="expand = false"
            @click="expand = true"
            :value="searchKeyword"
            v-model="searchKeyword"            
            clearable
          >
          </v-text-field>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="searchKeyword" class="keyboard"> </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table
          hide-default-footer
          :items-per-page="-1"
          :height="viewBoxHeight"
          :headers="header"
          :items="list"          
          fixed-header          
        >
          <template v-slot:header.partiesName="{ header }">
            <span class="d-flex justify-center">{{ header.text }}</span>
          </template>
          <template v-slot:header.caseNo="{ header }">
            <span class="d-flex justify-center">{{ header.text }}</span>
          </template>
          <template v-slot:header.judgeName="{ header }">
            <span class="d-flex justify-center">{{ header.text }}</span>
          </template>
          <template v-slot:header.courtRoomLocation="{ header }">
            <span class="d-flex justify-center">{{ header.text }}</span>
          </template>
          <template v-slot:header.dateTime="{ header }">
            <span class="d-flex justify-center">{{ header.text }}</span>
          </template>
          <template v-slot:item.caseNo="{ item }">
            <strong>{{ item.crt_caseno }}</strong>
          </template>
          <template v-slot:item.partiesName="{ item }">
            <div class="my-7">
              <span v-if="item.crt_casesealed === '1'"> ####SEALED#### </span>
              <span v-else v-for="(party, i) in item.partyList" :key="i">
                <v-icon>mdi-circle-medium</v-icon>
                {{ party.fullName }}
              </span>
            </div>
          </template>
          <template v-slot:item.judgeName="{ item }">
            <span v-if="item.crt_casesealed === '1'"> ####SEALED#### </span>
            <span v-else>{{ item.crt_judge }}</span>
          </template>
          <template v-slot:item.courtRoomLocation="{ item }">
            <v-btn
              v-if="
                item.crt_casesealed === '1' ||
                !Object.prototype.hasOwnProperty.call(item, 'crt_courtroom')
              "
              @click="handleCourtLocation(item.crt_caseno, 'clerks office')"
              elevation="0"
              color="primary"
              width="100%"
            >
              <v-icon left>mdi-gesture-tap</v-icon>
              Visit Clerk's Office
            </v-btn>
            <v-btn
              @click="handleCourtLocation(item.crt_caseno, item.crt_courtroom)"
              v-else
              width="100%"
              elevation="0"
              color="primary"
            >
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.crt_courtroom | trimLength }}
            </v-btn>
          </template>
          <template v-slot:item.dateTime="{ item }">
            <span v-if="item.crt_casesealed === '1'"> ##SEALED## </span>
            <span v-else>{{ item.crt_hearingdatetime | dateAndTime }}</span>
          </template>
        </v-data-table>
        <!-- <v-card class="pa-2 mb-2 text-decoration-underline" flat color="">
          <v-row width="100%">
            <v-col cols="2">
              <strong>Case Number</strong>
            </v-col>
            <v-col cols="4">
              <strong>Parties Names</strong>
            </v-col>
            <v-col cols="2">
              <strong>Judge Name</strong>
            </v-col>
            <v-col cols="2">
              <strong>Court Room Location</strong>
            </v-col>
            <v-col cols="2">
              <strong>Date/Time</strong>
            </v-col>
          </v-row>
        </v-card>
        <v-virtual-scroll
          :items="list"
          :item-height="90"
          :height="viewBoxHeight"
          class="overflow-x-hidden"
        >
          <template v-slot:default="{ item }">
            <v-card
              @click="expand = false"
              class="py-4 px-2"
              outlined
              flat
              color="grey lighten-4"
            >
              <v-row width="100%">
                <v-col cols="2" align-self="center">
                  <strong>{{ item.crt_caseno }}</strong>
                </v-col>
                <v-col align="start" cols="4" align-self="center">
                  <span v-if="item.crt_casesealed === '1'">
                    ####SEALED####
                  </span>
                  <span v-else v-for="(party, i) in item.partyList" :key="i">
                    <v-icon>mdi-circle-medium</v-icon>
                    {{ party.fullName }}                    
                  </span>
                </v-col>
                <v-col cols="2" align-self="center">
                  <span v-if="item.crt_casesealed === '1'">
                    ####SEALED####
                  </span>
                  <span v-else>{{ item.crt_judge }}</span>
                </v-col>
                <v-col cols="2" align-self="center">
                  <v-btn
                    v-if="
                      item.crt_casesealed === '1' ||
                      !Object.prototype.hasOwnProperty.call(
                        item,
                        'crt_courtroom'
                      )
                    "
                    @click="
                      handleCourtLocation(item.crt_caseno, 'clerks office')
                    "
                    elevation="0"
                    color="primary"
                    width="100%"
                  >
                    <v-icon left>mdi-gesture-tap</v-icon>
                    Visit Clerk's Office
                  </v-btn>
                  <v-btn
                    @click="
                      handleCourtLocation(item.crt_caseno, item.crt_courtroom)
                    "
                    v-else
                    width="100%"
                    elevation="0"
                    color="primary"
                  >
                    <v-icon left>mdi-gesture-tap</v-icon>
                    {{ item.crt_courtroom | trimLength }}
                  </v-btn>
                </v-col>
                <v-col cols="2" align-self="center">
                  <span v-if="item.crt_casesealed === '1'"> ##SEALED## </span>
                  <span v-else>{{
                    item.crt_hearingdatetime | dateAndTime
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-virtual-scroll> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "hearingList",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      expand: false,
      searchKeyword: null,
      viewBoxHeight: 600,
      header: [
        {
          text: "Case Number",
          align: "center",
          value: "caseNo",
          width: "200",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Parties Name",
          align: "start",
          value: "partiesName",
          width: "750",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Judge Name",
          align: "center",
          value: "judgeName",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Court Room Location",
          align: "center",
          value: "courtRoomLocation",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Date/Time",
          align: "center",
          value: "dateTime",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getHeraingList"]),
    list() {
      if (this.searchKeyword === "" || this.searchKeyword === null) {
        return this.getHeraingList;
      } else if (
        this.searchKeyword.match(/D-/) ||
        this.searchKeyword.match(/M-/) ||
        this.searchKeyword.match(/d-/) ||
        this.searchKeyword.match(/m-/)
      ) {
        return this.getHeraingList.filter(
          (item) =>
            !item.crt_caseno
              .toLowerCase()
              .indexOf(this.searchKeyword.toLowerCase())
        );
      } else {
        return this.getHeraingList.filter((item) => {
          if (
            item.partyList.filter((pr) =>
              pr.fullName !== null
                ? !pr.fullName
                    .toLowerCase()
                    .indexOf(this.searchKeyword.toLowerCase())
                : false
            ).length > 0 ||
            item.partyList.filter((pr) =>
              pr.firstName !== null
                ? !pr.firstName
                    .toLowerCase()
                    .indexOf(this.searchKeyword.toLowerCase())
                : false
            ).length > 0 ||
            item.partyList.filter((pr) =>
              pr.middleName !== null
                ? !pr.middleName
                    .toLowerCase()
                    .indexOf(this.searchKeyword.toLowerCase())
                : false
            ).length > 0 ||
            item.partyList.filter((pr) =>
              pr.lastName !== null
                ? !pr.lastName
                    .toLowerCase()
                    .indexOf(this.searchKeyword.toLowerCase())
                : false
            ).length > 0
          ) {
            return item;
          }
          return false;
        });
      }
    },
  },
  watch: {
    expand(n, o) {
      if (n) {
        this.viewBoxHeight = 370;
      } else {
        this.viewBoxHeight = 600;
        //user session logging
        this.$store.commit("setTouchRequest", {
          module: "Todays Hearing",
          action: `Search keyword - " ${this.searchKeyword} "`,
          response: `Found ${this.list.length} records.`,
          timeStamp: new Date(),
          requestType: "Touch",
        });
      }
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      console.log("button", button);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(caseNo, location) {
      let courtType = "";
      if (location !== "clerks office") {
        if (caseNo[0].toLowerCase() === "m") {
          courtType = "Magistrate";
        } else {
          courtType = "District";
        }
        let query = courtType + " " + location;
        console.log("Requested courtroom to QnA: ", query);
        this.$store.dispatch("requestQnA", query);
      } else {
        this.$store.dispatch("requestQnA", location);
      }
      //user session logging
      this.$store.commit("setTouchRequest", {
        module: "Todays Hearing",
        action: `Location request for Case No - "${caseNo}"`,
        response: location,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
  },
  mounted() {
    // if (this.getHeraingList.length === 0) {
      this.$store.dispatch("fetchList").then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    // }
  },
  filters: {
    dateAndTime(val) {
      if (!val) {
        return "-";
      }      
      return moment(val).format("[Today -] LT");
    },
    trimLength(val) {
      if (val === null) return val;
      if (val.length <= 30) {
        return val;
      }
      return `${val.substring(0, 23)}...`;
    },
  },
};
</script>

<style>
.containerBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #6b1530 !important;
  border-radius: 8px !important;
  overflow-y: scroll;
}
.hg-theme-default{
  background-color: #bcbcbc !important;
  font-weight: bolder !important;
  font-size: x-large !important;
}
</style>