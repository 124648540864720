var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-n15",attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-list',{attrs:{"dark":"","color":"primary","flat":"","height":"800"}},[_c('span',{staticClass:"text-h4 d-flex justify-center pa-2"},[_vm._v(" Map Directory ")]),_c('v-list',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-list-item-group',{staticClass:"mt-2",attrs:{"color":"white"}},[_c('v-virtual-scroll',{staticClass:"scrollClass",attrs:{"bench":"0","items":_vm.maps,"item-height":"64","height":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-divider',{attrs:{"color":"white"}}),_c('v-list-item',{on:{"click":function($event){return _vm.selectImage(
                  item.mapImage,
                  item.mapFloor,
                  item.speech,
                  item.mapName
                )}}},[_c('v-list-item-content',[_c('span',{staticClass:"white--text text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm._f("trimLength")(item.mapName))+" ")])])],1)]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-tabs',{attrs:{"active-class":"tabClass","background-color":"primary","centered":"","height":"70","dark":""},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},_vm._l((_vm.$t('floors')),function(floor,index){return _c('v-tab',{key:index,staticClass:"text-h5 pa-5",on:{"click":function($event){return _vm.selectImage(
          floor.floorImage.url,
          floor.floorNum,
          floor.speech,
          floor.floorName
        )}}},[_vm._v(" "+_vm._s(floor.floorName)+" ")])}),1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-img',{attrs:{"src":_vm.url,"width":"79%"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }