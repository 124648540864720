import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// The getters are to be called in the way below only when Map Datastructure is used.

// Getters for Maps -> English Language
let getMapsEnglish = () => {
  return store.getters.getMapsEnglish
}

// Getters for Maps -> Spanish Language
let getMapsSpanish = () => {
  return store.getters.getMapsSpanish
}

// Getters for Faq Category -> English Language
let getFaqCategoriesEnglish = () => {
  return store.getters.getFaqCategoriesEnglish
}

// Getters for Faq Category -> Spanish Language
let getFaqCategoriesSpanish = () => {
  return store.getters.getFaqCategoriesSpanish
}

// Getters for Court Type -> English Language
let getCourtTypeEnglish = () => {
  return store.getters.getCourtTypeEnglish
}

// Getters for Court Type -> Spanish Language
let getCourtTypeSpanish = () => {
  return store.getters.getCourtTypeSpanish
}

// Getters for Form Type -> English Language
let getFormTypeEnglish = () => {
  return store.getters.getFormTypeEnglish
}

// Getters for Form Type -> Spanish Language
let getFormTypeSpanish = () => {
  return store.getters.getFormTypeSpanish
}

// Getters for Forms -> English Language
let getFormsEnglish = () => {
  return store.getters.getFormsEnglish
}

// Getters for Forms -> Spanish Language
let getFormsSpanish = () => {
  return store.getters.getFormsSpanish
}

// Getters for Form Packets -> English Language
let getFormPacketsEnglish = () => {
  return store.getters.getFormPacketsEnglish
} 

// Getters for Form Packets -> Spanish Language
let getFormPacketsSpanish = () => {
  return store.getters.getFormPacketsSpanish
} 


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Homepage
      homepages: store.getters.getHomepageEnglish,
      
      // Faqs
      faqs: store.getters.getFaqsEnglish,
      faqCategory: getFaqCategoriesEnglish,
      srlGuide: store.getters.getSrlGuideEnglish,
      
      // Maps
      maps: getMapsEnglish,
      floors: store.getters.getFloorsEnglish,
      
      // Forms
      formType: getFormTypeEnglish,
      formPackets: getFormPacketsEnglish, 
      formCategory: store.getters.getFormCategoryEnglish, 
      forms: getFormsEnglish,
      courtType: getCourtTypeEnglish
    },
    es: {
      // Homepage
      homepages: store.getters.getHomepageSpanish,
      // Faqs
      faqs: store.getters.getFaqsSpanish,
      faqCategory: getFaqCategoriesSpanish,
      srlGuide: store.getters.getSrlGuideSpanish,
      // Maps
      maps: getMapsSpanish,
      floors: store.getters.getFloorsSpanish,
      // Forms
      formType: getFormTypeSpanish,
      formPackets: getFormPacketsSpanish, 
      formCategory: store.getters.getFormCategorySpanish, 
      forms: getFormsSpanish,
      courtType: getCourtTypeSpanish
    }
  }
})
