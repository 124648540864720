import {
    floorSpanishQuery,
    allFormFirstSpanishQuery,
    allFormSecondSpanishQuery,
    allFormThirdSpanishQuery,
    mapSpanishQuery,
    faqSpanishQuery,
    faqCategorySpanishQuery,
    srlGuideSpanishQuery,
    formCategorySpanishQuery,
    formTypeSpanishQuery,
    formPacketSpanishQuery,
    homepageSpanishQuery,
    CourtTypeSpanishQuery
} from '@/graphql/querySpanish';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        homepageSpanish: [],
        floorsSpanish: [],
        mapsSpanish: new Map(),
        formsSpanish: new Map(),
        faqsSpanish: [],
        faqCategorySpanish: [],
        srlGuideSpanish: new Map(),
        formCategoriesSpanish: [],
        formTypesSpanish: [],
        formPacketsSpanish: new Map(),
        courtTypeSpanish: new Map()

    },
    getters: {
        getHomepageSpanish: state => state.homepageSpanish,
        getFloorsSpanish: state => state.floorsSpanish,
        getMapsSpanish: state => state.mapsSpanish,
        getFaqsSpanish: state => state.faqsSpanish,
        getFaqCategoriesSpanish: state => state.faqCategorySpanish,
        getSrlGuideSpanish: state => state.srlGuideSpanish,
        getFormsSpanish: state => state.formsSpanish,
        getFormCategorySpanish: state => state.formCategoriesSpanish,
        getFormTypeSpanish: state => state.formTypesSpanish,
        getFormPacketsSpanish: state => state.formPacketsSpanish,
        getCourtTypeSpanish: state => state.courtTypeSpanish
    },
    mutations: {
        setHomepageSpanish(state, page) {
            state.homepageSpanish.push(page)
        },
        setFloorsSpanish(state, floor) {
            console.log('Spanish', floor)
            state.floorsSpanish.push(floor)
        },
        setMapsSpanish(state, map) {
            state.mapsSpanish.set(map.mapName.toLowerCase(), map)
        },
        setFormsSpanish(state, form) {
            state.formsSpanish.set(form.id, form)
        },
        setFaqsSpanish(state, faqs) {
            faqs.forEach(faq => {
                let data = {
                    question: faq.question,
                    answer: faq.answer.replace(/\n/g, '<br />'),
                    category: faq.category
                }
                state.faqsSpanish.push(data)
            })
            //state.faqs = faqs
        },
        setFaqCategorySpanish(state, faqCategories) {
            state.faqCategorySpanish = faqCategories
        },
        setSrlGuideSpanish(state, guide) {
            state.srlGuideSpanish.set(guide.guideName.toLowerCase(), {
                guideName: guide.guideName,
                category: guide.category,
                srlFile: guide.srlFile.url,
                guideUrl: guide.srlGuideLink
            })
        },
        setFormCategoriesSpanish(state, categories) {
            state.formCategoriesSpanish = categories
        },
        setFormTypesSpanish(state, types) {
            state.formTypesSpanish = types
        },
        setFormPacketsSpanish(state, packet) {
            state.formPacketsSpanish.set(packet.packetName.toLowerCase(), packet)
        },
        setCourtTypesSpanish(state, formCourtType) {
            state.courtTypeSpanish.set(formCourtType.courtType, formCourtType.category)
        }
    },
    actions: {
        fetchHomePageSpanish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: homepageSpanishQuery
            }).then(items => {
                items.data.homepages.forEach(page => {
                    dispatch('saveInCache', page.menuIcon.url)
                        .then(response => {
                            page.menuIcon.url = response
                            commit('setHomepageSpanish', page);
                        })
                })


            })
        },
        // Fetch Floor from strapi
        fetchFloorsSpanish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: floorSpanishQuery
            }).then(floors => {
                floors.data.floors.forEach(floor => {
                    dispatch('saveInCache', floor.floorImage.url)
                        .then(response => {
                            floor.floorImage.url = response;
                            commit('setFloorsSpanish', floor)
                        })
                })

            })
        },

        // Fetching Maps from Strapi
        fetchMapsSpanish({ commit, getters, dispatch }) {
            getters.getApolloClient.query({
                query: mapSpanishQuery
            }).then(maps => {
                maps.data.maps.forEach(map => {
                    dispatch('saveInCache', map.mapImage.url)
                        .then(response => {
                            commit('setMapsSpanish', {
                                mapName: map.mapName,
                                mapFloor: map.mapFloor,
                                mapImage: response,
                                speech: map.speech
                            })
                        })
                })

            })
        },

        // Fetch Forms from strapi
        fetchFormsSpanish({ commit, dispatch, getters }) {
            getters.getApolloClient.query({
                query: allFormFirstSpanishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsSpanish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })

            })

            getters.getApolloClient.query({
                query: allFormSecondSpanishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsSpanish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })
            })

            getters.getApolloClient.query({
                query: allFormThirdSpanishQuery
            }).then(forms => {
                forms.data.allForms.forEach(form => {
                    dispatch('saveInCache', form.formPdf.url)
                        .then(response => {
                            commit('setFormsSpanish', {
                                id: form.id,
                                formName: form.formName,
                                formCategory: form.formCategory,
                                displayIcon: form.displayIcon,
                                formPdf: response,
                                pdfUrl: form.pdfUrl
                            })
                        })
                })
            })
        },

        fetchFaqsSpanish({ commit, getters }) {
            getters.getApolloClient.query({
                query: faqSpanishQuery
            }).then(faqs => {
                commit('setFaqsSpanish', faqs.data.faqs);

            })
        },

        fetchFaqCategorySpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: faqCategorySpanishQuery
            }).then(faqCategory => {
                commit('setFaqCategorySpanish', faqCategory.data.faqCategories)

            })
        },

        fetchSrlGuideSpanish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: srlGuideSpanishQuery
            }).then(srlGuides => {
                srlGuides.data.srlGuides.forEach(guide => {
                    dispatch('saveInCache', guide.srlFile.url)
                        .then(response => {
                            guide.srlFile.url = response;
                            commit('setSrlGuideSpanish', guide);
                        })
                })

            })
        },

        fetchFormCategoriesSpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: formCategorySpanishQuery
            }).then(categories => {
                commit('setFormCategoriesSpanish', categories.data.formCategories)

            })
        },

        fetchFormTypeSpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: formTypeSpanishQuery
            }).then(types => {
                commit('setFormTypesSpanish', types.data.formTypes)
            })
        },
        fetchFormCourtTypeSpanish({ commit, getters }) {

            getters.getApolloClient.query({
                query: CourtTypeSpanishQuery
            }).then(types => {
                types.data.courtTypes.forEach(courtType => {
                    commit('setCourtTypesSpanish', courtType)
                })
            })
        },
        fetchFormPacketsSpanish({ commit, dispatch, getters }) {

            getters.getApolloClient.query({
                query: formPacketSpanishQuery
            }).then(packets => {
                packets.data.formPackets.forEach(packet => {
                    let files = [];
                    packet.packetFiles.forEach(file => {
                        dispatch('saveInCache', file.url)
                            .then(response => {
                                files.push(response)

                            })
                    })
                    commit('setFormPacketsSpanish', {
                        packetName: packet.packetName,
                        packetDesc: packet.packetDesc,
                        packetLink: packet.packetLink,
                        packetFiles: files
                    })
                })

            })
        }
    }
}
