import axios from 'axios';
import { fn } from 'moment';

export default {
    state: {
        hearingList: [],
    },
    getters: {
        getHeraingList: state => state.hearingList,
    },
    mutations: {
        setHearingList(state, list) {
            state.hearingList = list;
        }
    },
    actions: {
        fetchList({ dispatch }) {
            return new Promise((response, reject) => {
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/snapshotDataexchange/a763cd056f1b2405788443b7197e0708',
                    params: {
                        condition: "(crt_court='Taos County Magistrate Court in Taos' OR crt_court='Eighth Judicial District Taos County')",                        
                    },
                    headers: {}
                };
                console.log("CONFIG:", config);
                axios(config)
                    .then(result => {
                        // console.log(result.data);
                        dispatch('generateHearingList', result.data)
                            .then(() => {
                                response("List fetched successfully")
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            })
        },
        generateHearingList({ commit }, data) {
            return new Promise((response, reject) => {
                let temp = new Map();
                console.log('Total Records:', data.length)

                data.forEach((item) => {
                    if (!temp.has(item.crt_caseno)) {
                        let partyList = [];
                        let filteredResult = data.filter((obj) => {
                            return obj.crt_caseno === item.crt_caseno;
                        });
                        // console.log(`Case Number: ${item.crt_caseno}, Number: ${filteredResult.length}`)
                        if (filteredResult.length > 0) {
                            filteredResult.forEach(item => {
                                let fName = '';
                                let mName = '';
                                let lName = '';
                                let fullName = '';
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partyfirstname'))
                                    fName = item.crt_partyfirstname
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partymiddlename'))
                                    mName = item.crt_partymiddlename
                                if (Object.prototype.hasOwnProperty.call(item, 'crt_partylastname'))
                                    lName = item.crt_partylastname
                                fullName = fName + ' ' + mName + ' ' + lName;
                                partyList.push({
                                    firstName: fName,
                                    middleName: mName,
                                    lastName: lName,
                                    fullName: fullName.replace(/\s+/g, ' ').trim()
                                })
                            })
                            let mergedData = {
                                ...filteredResult[0],
                                partyList: partyList
                            }
                            temp.set(item.crt_caseno, mergedData);
                        }
                    }
                });
                commit('setHearingList', Array.from(temp.values()))
                response('Data cleaned')
            })

        }
    }
}